
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "b1bb0327d4a076948ee5ea834ffa7ff6df927999",
  "nuxt": {
    "buildId": "b9125285-b260-47e8-832b-381f7728e85b"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
